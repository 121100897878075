html {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}
body {
  overflow: auto;
  color: white;
}
* {
  font-family: monospace;
}

#root {
  /* background-image: url(https://cdn.midjourney.com/f4d40d39-40e1-4037-b3fa-e682861d5b09/grid_0.png); */
  /* background-image: url(https://media.discordapp.net/attachments/997271858925809714/1055996061019279390/MaxSwell_Beggar_cyberpunk_glow_f6092500-736b-441c-ad34-4a1426ff9a5f.png); */
  /* background-image: url(https://cdn.midjourney.com/35c18ec9-03cf-4714-bd73-b6fc75acdae3/grid_0.png); */
  /* background-image: url(https://cdn.discordapp.com/attachments/1015732586619871293/1193647111477153832/jean_swell_androgyne_person_praying_for_getting_a_lot_of_money._0d872a6a-a5b1-415f-be2c-6a3d34bd67a2.png?ex=65ad7980&is=659b0480&hm=02c4eb61bcef57058fd54032739f530287fbd72d8c020c4591d677ee62c4e7d4&); */
  background-image: url(https://cdn.discordapp.com/attachments/1015732586619871293/1193649139335053334/jean_swell_androgyne_person_praying_for_getting_a_lot_of_money._05fcd246-5baf-467e-8d34-ff94e54a225e.png?ex=65ad7b63&is=659b0663&hm=c5da07a53b9319440256210bd1021991883cd49bb319e6f96e7d6565e648392f&);
  /* background-image: url(https://github.com/MaxInMoon/public_assets/blob/main/poc_lotery/man_praing.jpg?raw=true); */
  background-size: cover;
  background-position: center;
  min-width: 100vw;
  min-height: 100vh;
}

#obfuscator {
  position: fixed;
  z-index: 0;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6);
}

.App {
  position: relative;
  z-index: 1;
  background-size: cover;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.content {
  margin: 0 auto 0;
  width: 90%;
  max-width: 1200px;
  padding: 2em;
}

.alertContainer {
  padding: 3em 0;
  width: 80%;
  max-width: 600px;
  margin: auto;
  text-align: center;
}

.logoWojak {
  width: 192px;
  height: 192px;
  margin-bottom: -0.8em;
}

.metamaskLogo {
  width: 192px;
  height: 192px;
  margin-top: 2em;
}

.title {
  margin-bottom: 1em;
}
/* .title:nth-of-type(1) {
  margin-top: 0;
} */

.sectionSpacer {
  margin-top: 6em;
  margin-bottom: 6em;
}

.btnAction.btnParticipate {
  opacity: 1;
}
.btnAction.btnDrawLottery {
  /* background-color: rgba(231, 193, 247, 0.6) !important; */
  background-color: #d9363e94 !important;
  box-shadow: 0 2px 0 #d9363e94 !important;
}

.btnRow {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
}

/** ANT */
.ant-modal-content {
  background-color: #141414 !important;
  color: white;
}
.ant-modal-content .ant-modal-confirm-title,
.ant-modal-content .ant-modal-confirm-content {
  color: white !important;
}

.ant-alert {
  color: white;
  background-color: rgba(58, 38, 95, 0.33) !important;
}
.ant-btn {
  background-color: rgb(194 172 234 / 13%) !important;
  color: white;
}

.ant-input {
  background-color: rgb(194 172 234 / 13%) !important;
  color: white;
  border-color: rgba(255, 255, 255, 0.473);
}
