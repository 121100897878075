.root {
  .cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, 140px);
    gap: 20px;

    .card {
      height: 140px;
      margin-bottom: 1em;
      background-color: rgba(64, 61, 68, 0.33);
      border-color: rgba(255, 255, 255, 0.13);
      opacity: 0.9;
      position: relative;

      background-image: url("https://github.com/MaxInMoon/public_assets/blob/main/poc_lotery/man_praing.jpg?raw=true");
      background-size: cover;

      &:nth-child(1n) {
        background-image: url("https://github.com/MaxInMoon/public_assets/blob/main/poc_lotery/man_praing.jpg?raw=true");
      }
      &:nth-child(2n) {
        background-image: url("https://cdn.discordapp.com/attachments/1015732586619871293/1193649145274187906/jean_swell_androgyne_person_praying_for_getting_a_lot_of_money._72eb50ff-26c4-435d-ba31-6df80b3caf69.png?ex=65ad7b65&is=659b0665&hm=3f9b39915d3683c2547dd1d1c62956247050e33b9caac42f5d3bfdfca008f511&");
      }
      &:nth-child(3n) {
        background-image: url("https://cdn.discordapp.com/attachments/1015732586619871293/1193652981665632296/jean_swell_androgyne_person_praying_for_getting_a_lot_of_money._0d9ed873-f279-47fd-8ec5-18e628f01a39.png?ex=65ad7ef7&is=659b09f7&hm=b265e25aef5d4bb973f00acc2e230ad960818e3169117615ea6dfb13cf57db4a&");
      }
      background-position: center;
      background-repeat: no-repeat;
      // background-size: 130%;

      :global(.ant-card-body) {
        padding: 12px 12px 12px 16px;
        display: flex;
        align-items: center;
      }

      color: white;

      .label {
        margin: 0;
        // margin-left: 0.5em;
        background-color: rgba(0, 0, 0, 0.65);
        border-radius: 8px;
        min-width: 30px;
        text-align: center;
        position: absolute;
        left: 12px;
        bottom: 12px;
      }

      img {
        width: 100px;
        height: 100px;
      }
    }
  }
}
