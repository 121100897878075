.root {
  margin-top: 4em;
  margin-bottom: 4em;
  display: grid;
  grid-gap: 5vw;
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
  > div {
    padding: 1em;
    background-color: rgb(194 172 234 / 13%);
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  }
  input {
    width: 80px;
  }
}
