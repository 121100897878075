.root {
  .legend {
    opacity: 0.4;
    font-style: italic;
  }
  .card {
    margin-bottom: 1em;
    background-color: rgba(64, 61, 68, 0.33);
    border-color: rgba(255, 255, 255, 0.13);
    :global(.ant-card-body) {
      padding: 12px 12px 12px 16px;
      display: flex;
      align-items: center;
    }

    color: white;

    .playerAddress {
      margin: 0;
      padding-left: 0.5em;
    }
  }
}
