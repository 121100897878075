.card {
  background-color: rgb(194 172 234 / 13%);
  display: flex;
  width: 100%;
  overflow: hidden;
  border-radius: 4px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

  color: white;

  .row {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  padding: 2em;

  .subtitle {
    font-size: 1.6em;
    margin-bottom: 0.4em;
  }

  .label {
    font-size: 1em;
    word-break: break-all;
    margin-bottom: 0.2em;
  }
  .labelSmall {
    font-size: 0.7 em;
  }

  .yourBet {
    flex-shrink: 0;
  }
}
