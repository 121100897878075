.header {
  padding: 1em;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;

  h2,
  p {
    margin: 0;
  }

  .right {
    display: flex;
    align-items: center;
    .copyToClipboard {
      margin-right: 1em;
      display: flex;
      align-items: center;
      cursor: pointer;
      p {
        margin-right: 0.2em;
      }
    }
  }
}

:global(.ant-tooltip) {
  p {
    margin: 0;
    padding: 10px;
  }
}
